.item {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  scroll-snap-align: start;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: filter 0.3s ease;
}

.background.blurred {
  filter: blur(30px);
}

.content {
  position: relative;
  z-index: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  color: white;
  text-align: center;
}

.question {
  font-size: 2.5rem;
  margin-bottom: 20px;
  text-shadow:
          2px 2px 4px rgba(0, 0, 0, 0.7),
          2px -2px 4px rgba(0, 0, 0, 0.7),
          -2px 2px 4px rgba(0, 0, 0, 0.7),
          -2px -2px 4px rgba(0, 0, 0, 0.7),
          0 0 10px rgba(0, 0, 0, 0.7);
}

.answer {
  font-size: 2rem;
  max-width: 80%;
  transition: filter 0.3s ease, opacity 0.3s ease;
  text-shadow:
          2px 2px 4px rgba(0, 0, 0, 0.7),
          2px -2px 4px rgba(0, 0, 0, 0.7),
          -2px 2px 4px rgba(0, 0, 0, 0.7),
          -2px -2px 4px rgba(0, 0, 0, 0.7),
          0 0 10px rgba(0, 0, 0, 0.7);
}

.answer.blurred {
  filter: blur(5px);
  opacity: 0.7;
}

.answer.visible {
  filter: blur(0);
  opacity: 1;
}
