.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.content {
  /*background-color: black;*/
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.question {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  color: white;
  cursor: pointer;
}

.fullText {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: white;
  text-align: center;
  cursor: pointer;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

.sourceLink {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  color: #4299e1;
  text-decoration: none;
  margin-top: auto;
  transition: color 0.3s;
}

.sourceLink:hover {
  color: #3182ce;
}
