.scroll-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    width: 100vw;
    height: 100vh;
    scroll-snap-type: y mandatory;
}

/* Скрываем скроллбар для всех браузеров */
.scroll-container::-webkit-scrollbar {
    display: none;
}

.scroll-container {
    -ms-overflow-style: none;  /* IE и Edge */
    scrollbar-width: none;  /* Firefox */
}

.scroll-item {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    scroll-snap-align: start;
}
